body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  margin-top: 40px;
}

/* ------------------------- VARIABLE RESETS -------------------------*/

:root {
	--mill-blue: #3b6289;
	--mill-green: #396d1b;
	--mill-table-silver: #7c8b96;
	--light-gray: #E2E2E2;
	--harbor-freight-red: #a31a2e;
	--bridgeport-gray: #494949;
	--air-force-blue: #002B80;
	--air-force-yellow: #D2AF39;
	--air-force-gold: #B6860A;
}

/* -------------------------- GLOBAL STYLES --------------------------*/

h1 { color: var(--air-force-blue); margin-top: 0.5em; }
h2 { color: var(--mill-blue); }

a:visited { color: var(--mill-table-silver); }
a:focus, a:hover { color: var(--air-force-gold); }
a:active { color: var(--harbor-freight-red); }

a.navbar-brand { font-weight: bold; padding: 0; color: white; }

a.nav-link { color: white; padding: 0 1rem; }
a.nav-link:visited { color: white; }
a.nav-link:hover { color: var(--air-force-yellow); text-decoration: underline; }
a.nav-link:active { color: var(--air-force-gold); }

button.btn-primary { background-color: var(--mill-blue); border-color: var(--mill-blue); }
button.btn-primary:focus, button.btn-primary:hover { background-color: var(--mill-green); border-color: var(--mill-green); }
button.btn-primary:focus, button.btn-primary:active { box-shadow: 0 0 0 4px #a4b797!important; }
button.btn-primary[disabled] { background-color: var(--mill-table-silver); border-color: var(--mill-table-silver); }

img.file-icon { max-height: 1em; width: auto; }
img.edit-icon { max-height: 0.6em; vertical-align: text-top; }

/* ------------------------ COMPONENT STYLES -------------------------*/

#nav-primary { background-color: #294256; }
#nav-primary h2 {
  font-weight: bold;
  margin: 0px;
  color: white;
}

#nav-primary.paused { 
  background-color: #ffc107;
}

#nav-primary.paused h2 {
  color: #212529;
}

#nav-primary > .container { 
  display: flex; 
  align-items: baseline; 
  justify-content: space-between;
}

#nav-secondary { background-color: var(--light-gray); }
#nav-secondary > .container { display: flex; align-items: center; justify-content: space-between; }
#nav-secondary a.nav-link { color: var(--air-force-blue); }
#nav-secondary a.nav-link:visited { color: var(--air-force-blue); }
#nav-secondary a.nav-link:hover { color: var(--harbor-freight-red); }
#nav-secondary a.nav-link:active { color: var(--air-force-gold); }

table.doc-table th:nth-of-type(2), table.doc-table td:nth-of-type(2) { text-align: center; }
table.doc-table th:nth-of-type(3), table.doc-table td:nth-of-type(3) { text-align: right; }

table.doc-table i {
  font-size: 22px;
  line-height: 10px;
}

div.view-docs { float: right; margin-top: 0.5em; }


label.form-label { font-weight: bold; }

.student-answers-cards { margin-top: 1.5em; }
.student-answers-cards .col { margin-bottom: 1.5em; }
.student-answers-cards .card { border-color: var(--mill-table-silver); }
.student-answers-cards .card-header { display: flex; justify-content: space-between; background-color: var(--mill-table-silver); color: white; }
.student-answers-cards .card-team { font-size: 1rem; color: white; font-weight: bold; margin: 0; }
.student-answers-cards .card-date { margin: 0; }
.student-answers-cards .card-body { max-height: 500px; overflow: auto; }
.student-answers-cards .card-body h3 { font-size: 1.2rem; }
.student-answers-cards .card-body p { margin-left: 1.5em; }

.case-studies-cards { margin-top: 1.5em; }
.case-studies-cards .col { margin-bottom: 1.5em; }
.case-studies-cards .card { border-color: var(--mill-table-silver); }
.case-studies-cards .card-header { display: flex; justify-content: space-between; background-color: var(--mill-table-silver); color: white; }
.case-studies-cards h2 { font-size: 1rem; color: white; font-weight: bold; margin: 0; }

h1 i { font-size: 0.4em; position: relative; bottom: 0.75em; }
.page-list-draggable { margin: 0.25em 0; }
.page-list-draggable .list-group-item { border-radius: 5px; border-style: dashed; border-color: rgba(0, 0, 0, 0.25); }

table.user-table td { vertical-align: middle; }
table.user-table td:last-of-type { text-align: right; }

table.user-page-table td:first-of-type { font-weight: bold; }
table.user-page-table td:nth-of-type(2) div { max-height: 12em; display: block; overflow-x: auto; }
table.user-page-table td:last-of-type i { white-space: nowrap; }

h2.accordion-header { position: relative; }
button.csv-button { position: absolute; z-index: 10; right: 4em; top: 0.35em; }

.question-details { padding: 1em; border: 1px solid lightgray; border-radius: 5px; margin-bottom: 1em; }
.question-table { margin-bottom: 0; }
.question-table td p:last-of-type { margin-bottom: 0; }

.document-upload { border: 1px dashed lightgray; border-radius: 5px; margin: 1em 0; text-align: center; color: darkgray; padding: 1em; }
.document-upload i { font-size: 2em; display: block; }

.document-table td:last-of-type { text-align: right; }

.custom-disabled {
  background-color: var(--mill-table-silver) !important;
  border-color: var(--mill-table-silver) !important;
  opacity: .65;
}

#page-text-preview {
  padding: 20px;
  border: 1px dashed grey;
  border-radius: 5px;
  box-shadow: 5px 5px 4px #e0e0e0;
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 13px;
}

#file-upload-form {
  display: none;
}